body {
   margin: 0;
   -webkit-font-smoothing: antialiased;
   -moz-osx-font-smoothing: grayscale;
   font-family: "Noto Sans", sans-serif;
   overflow: hidden;
}

h1,
h2,
h3,
h4,
h5,
h6 {
   font-family: "Noto Sans", sans-serif !important;
   font-weight: 700;
}

* {
   -webkit-user-select: none;
   -khtml-user-select: none;
   -moz-user-select: -moz-none;
   -o-user-select: none;
   user-select: none;
}

input {
   -webkit-user-select: text;
   -khtml-user-select: text;
   -moz-user-select: text;
   -o-user-select: text;
   user-select: text;
}

img {
   pointer-events: none;
}
